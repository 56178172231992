import React from "react";

interface FooterProps {}

const Footer: React.FC<FooterProps> = ({}) => {
  return (
    <footer
      style={{ backgroundColor: "#FD345A" }}
      className="h-10 items-center w-screen grid grid-cols-3 text-center md:text-left fixed bottom-0 pl-8 pr-8 text-white text-sm"
    >
      <p className="hidden xl:block col-span-2">
        &copy; Mylou Matrix Ltd {new Date().getFullYear()}. PlaylistKING&reg; is
        a registered trademark of Mylou Matrix Ltd, U.S. registration number:
        5,867,613 &amp; 6,672,694. Patent No: US 11,681,738.
      </p>
      <p className="col-span-3 md:col-span-2 xl:hidden">
        &copy; Mylou Matrix Ltd {new Date().getFullYear()}. PlaylistKING&reg;
      </p>
      <a
        href="http://www.myloumatrix.com/aa_terms.php"
        target="_blank"
        rel="noreferrer"
        className="hidden text-right md:block"
      >
        Privacy, Terms and Cookies
      </a>
    </footer>
  );
};
export default Footer;
