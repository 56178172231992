import React from "react";

import { Disclosure } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";

interface NavbarProps {}

const navigation = [
  { name: "ABOUT US", href: "http://www.myloumatrix.com/" },
  { name: "PRO LOGIN", href: "https://pro.playlistking.com/" },
  { name: "LITE LOGIN", href: "https://lite.playlistking.com/" },
  {
    name: "RECOMMENDATIONS LOGIN",
    href: "https://recommendations.playlistking.com/",
  },
];

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(" ");
}

const Navbar: React.FC<NavbarProps> = ({}) => {
  return (
    <Disclosure
      className="w-screen fixed top-0 z-10"
      as="nav"
      style={{ backgroundColor: "#FD345A" }}
    >
      {({ open }) => (
        <>
          <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
            <div className="relative flex items-center justify-between h-12">
              <div className="absolute inset-y-0 right-0 flex items-center lg:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-white hover:text-black hover:bg-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex-1 flex items-center justify-between sm:items-stretch sm:justify-between">
                <div className="flex-shrink-0 flex items-center">
                  <img
                    className="h-8 w-auto"
                    src="/images/logo-text-white.png"
                    alt="PlaylistKING logo"
                  />
                </div>
                {/* Links not in the MENU ICON */}
                <div className="hidden lg:block lg:ml-6">
                  <div className="flex space-x-6">
                    {navigation.map((item) => (
                      <a
                        key={item.name}
                        target="_blank"
                        rel="noreferrer"
                        href={item.href}
                        className={classNames(
                          "px-3 py-2 rounded-md text-xs xl:text-sm text-white"
                        )}
                      >
                        {item.name}
                      </a>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="lg:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1">
              {navigation.map((item) => (
                <Disclosure.Button
                  key={item.name}
                  as="a"
                  href={item.href}
                  className={classNames(
                    "block px-3 py-2 rounded-md text-base font-medium text-white"
                  )}
                >
                  {item.name}
                </Disclosure.Button>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default Navbar;
